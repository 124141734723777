<template>
  <div>
    <nav-bar v-if="isMobile" headerTitle="健身·加盟 首选超鹿" ref="head" :showBack="true" :headerBack="newAppBack"
      :showShare="true" @right-txt-click="shareShow = true" />
    <div :class="['page-content', isMobile ? '' : 'join-company-pc']">
      <img class="jion" :src="baseImgUrl + 'join1.png'" alt="">
      <div class="top1">
        <img class="jion" :src="baseImgUrl + 'join2.png'" alt="">
        <div :class="['edit', isMobile ? '' : 'join-company-pc']">
          <div :class="['name', isMobile ? '' : 'join-company-pc']">
            <van-field v-model.trim="name" clearable maxlength="10" />
          </div>
          <div :class="['phone', isMobile ? '' : 'join-company-pc']">
            <van-field v-model="mobilePhone" type="tel" clearable maxlength="11" />
          </div>
          <div :class="['city', isMobile ? '' : 'join-company-pc']">
            <span v-for="i in columns" @click="chose(i)" :class="city.indexOf(i.value) > -1 ? 'act' : ''">{{ i.text
            }}</span>
          </div>
          <img class="btn" @click="submit" :src="baseImgUrl + 'join-btn.png?v=1'" alt="">
        </div>
      </div>
      <img class="jion" :src="baseImgUrl + 'join3.png'" alt="">
      <img class="jion" :src="baseImgUrl + 'join4.png'" alt="">
      <img class="jion" :src="baseImgUrl + 'join5.png'" alt="">
      <div class="top1">
        <img class="jion" :src="baseImgUrl + 'join6.png'" alt="">
        <div :class="['edit', 'edit2', isMobile ? '' : 'join-company-pc']">
          <div :class="['name', isMobile ? '' : 'join-company-pc']">
            <van-field v-model.trim="name" clearable maxlength="10" />
          </div>
          <div :class="['phone', isMobile ? '' : 'join-company-pc']">
            <van-field v-model="mobilePhone" type="tel" clearable maxlength="11" />
          </div>
          <div :class="['city', isMobile ? '' : 'join-company-pc']">
            <span v-for="i in columns" @click="chose(i)" :class="city.indexOf(i.value) > -1 ? 'act' : ''">{{ i.text
            }}</span>
          </div>
          <img :class="['btn', 'btn2', isMobile ? '' : 'join-company-pc']" @click="submit"
            :src="baseImgUrl + 'join-btn.png?v=1'" alt="">

        </div>
      </div>
      <img class="jion join7" :src="baseImgUrl + 'join7.png'" alt="">
      <p :class="['company-remark', isMobile ? 'f24' : 'join-company-pc']">福州超体健康科技有限公司 企业 闽ICP备18001358号-1</p>
      <img :src="baseImgUrl + 'join-top.png'" :class="['top', isMobile ? '' : 'join-company-pc']" alt="" v-if="topShow"
        @click="toTOP">
    </div>
    <!-- 成功弹窗 -->
    <van-popup v-model="tipsShow" class="tips-pop">
      <p>提交成功</p>
      <p>感谢您的信任，我们将在3个工作日内联系您</p>
      <div @click="tipsShow = false">我知道了</div>
    </van-popup>
    <!--  分享  -->
    <common-share :value="shareShow" :shareParams="shareParams" @close="shareShow = false"
      @share-success="shareShow = false" :shareItem="['minifriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img :src="baseImgUrl + 'sharebg.png?v=1'" class="canvascss_bg" />
        </div>
      </template>
    </common-share>
  </div>
</template>

<script>
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, isMobile } from '@/lib/appMethod';
import Regular from '@/lib/regular'
import commonShare from '@/components/commonShare/newShare'
const webUrl = `${window.location.origin}/#/joinCompany/index`
import { appType } from '@/lib/appMethod'
import wx from 'weixin-js-sdk';

export default {
  components: {
    navBar, commonShare
  },
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/join-campany/241128/',
      editShow: false,
      name: '',
      mobilePhone: '',
      city: [],
      type: '',
      tipsShow: false,
      tag: 0,
      clickId: '',
      topShow: false,
      columns: [
        { text: '福州', value: '3' },
        { text: '厦门', value: '036652995ac5451fbb6791c3491e1c2d' },
        { text: '广州', value: '818447834079563776' },
        { text: '其他城市', value: '-1' },
      ],
      shareShow: false,
      appTypeStr: "",
      shareParams: {
        title: '超鹿好项目 加盟开放中',
        miniImage: 'https://img.chaolu.com.cn/ACT/join-campany/241128/mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      isMobile: isMobile(),
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrallFun)
  },
  async created() {
    this.appTypeStr = appType()
    this.clickId = this.$route.query.clickId || this.$route.query.gdt_vid || ''
    this.type = this.$route.query.type;
    this.init();
    // 分享的链接
    const uu = encodeURIComponent(`${webUrl}`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`

    if (this.appTypeStr === 'mini') {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    }
  },
  methods: {
    newAppBack,
    init() {
      initBack()
    },
    tagFun() {
      if (this.tag == 0) {
        this.tag = 3
        let timer = setInterval(() => {
          this.tag--
          if (this.tag == 0) {
            clearInterval(timer)
          }
        }, 1000)
        return true
      } else {
        return false
      }
    },
    scrallFun() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.topShow = scrollTop > 600
    },
    submit() {
      if (!this.name) {
        this.$toast('请输入姓名')
        return
      }
      if (!/^[\u4e00-\u9fa5A-Za-z]+$/g.test(this.name)) {
        this.$toast('请填写正确姓名');
        return
      }
      if (!Regular.isTelenum(this.mobilePhone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (this.city.length == 0) {
        this.$toast('请选择城市')
        return
      }
      if (!this.tagFun()) {
        return
      }
      let willCity = this.columns.filter(item => this.city.indexOf(item.value) > -1).map(item => item.text).join(',')
      this.$axios.post(this.baseURLApp + '/JoinApplication/submitApplication', {
        mobilePhone: this.mobilePhone,
        name: this.name,
        willCity,
        willCityId: this.city.join(','),
        type: this.type
      }).then((res) => {
        if (res.code == 1) {
          this.tipsShow = true
        } else {
          this.$toast(res.msg)
        }
        this.addUserAction('RESERVATION')
      })
    },
    toTOP() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    chose(item) {
      let index = this.city.indexOf(item.value)
      if (index > -1) {
        this.city.splice(index, 1)
      } else {
        this.city.push(item.value)
      }
    },
    addUserAction(actionType) {
      if (!this.clickId) {
        return false
      }
      this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`, {
        actionUrl: '/joinCompany/index',
        clickId: this.clickId,
        actionType: actionType
      }, false).then(res => {
        console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
      }).catch(r => {
        console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
      })
    },
  }
}
</script>

<style lang="less" scoped>
.page-content {
  font-size: 0px;

  &.join-company-pc {
    width: 600px;
    margin: 0 auto;
  }

  .jion {
    width: 100%;
    margin-top: -1px;
  }

  .join7 {
    margin-top: -1px;
  }

  .company-remark {
    text-align: center;
    height: 80px;
    background-color: #FFC915;
    margin-top: -3px;

    &.join-company-pc {
      height: 60px;
      font-size: 18px;
    }
  }

  .top1 {
    position: relative;

    .edit {
      position: absolute;
      top: 204px;
      left: 0;
      width: 100%;
      height: 100%;

      &.join-company-pc {
        top: 164px;
      }

      .name,
      .phone,
      .city {
        width: 82.8%;
        height: 76px;
        margin: 0 auto;

        &.join-company-pc {
          height: 60px;

          .van-cell {
            padding: 0 20px 0 188px;
            font-size: 22px;
          }
        }


        .van-cell {
          padding: 0 24px 0 238px;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background-color: transparent;
          font-weight: 600;

          /deep/.van-field__body {
            height: 100%;
          }
        }
      }

      .city {
        height: 141px;
        padding: 20px 0px 28px;
        &.join-company-pc {
          height: 110px;
          padding: 16px 15px 24px;
        }
      }

      .name {
        background: url(https://img.chaolu.com.cn/ACT/join-campany/name.png) no-repeat center/100% 100%;
      }

      .phone {
        background: url(https://img.chaolu.com.cn/ACT/join-campany/phone.png) no-repeat center/100% 100%;
        margin-top: 30px;

        &.join-company-pc {
          margin-top: 26px;
        }
      }

      .city {
        background: url(https://img.chaolu.com.cn/ACT/join-campany/city2.png) no-repeat center/100% 100%;
        font-size: 30px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        padding-left: 242px;
        box-sizing: border-box;
        font-weight: 600;
        flex-wrap: wrap;

        &.join-company-pc {
          padding-left: 190px;
          margin-top: 26px;

          span {
            font-size: 20px;
            margin-right: 10px;
          }

          >span::before {
            content: '';
            display: inline-block;
            width: 29px;
            height: 29px;
            background: url(https://img.chaolu.com.cn/ACT/join-campany/cycle.png) no-repeat center/100% 100%;
            margin-right: 6px;
          }
        }

        span {
          display: flex;
          align-items: center;
          // margin-right: 20px;
          width: 45%;
        }

        >span::before {
          content: '';
          display: inline-block;
          width: 36px;
          height: 36px;
          background: url(https://img.chaolu.com.cn/ACT/join-campany/cycle.png) no-repeat center/100% 100%;
          margin-right: 10px;
        }

        >span.act::before {
          background: url(https://img.chaolu.com.cn/ACT/join-campany/check.png) no-repeat center/100% 100%;
        }

        // .city-box {
        //   font-size: 28px;
        //   color: #000000;
        //   font-weight: 600;
        //   display: flex;
        //   justify-content: space-between;
        //   margin-left: 230px;
        //   margin-right: 32px;
        //   flex: 1;

        //   i {
        //     margin-top: 2px;
        //     display: flex;
        //     align-items: center;
        //   }
        // }
      }

    }

    .edit2 {
      top: 174px;

      &.join-company-pc {
        top: 148px;
      }
    }

    .btn {
      width: 62.8%;
      margin: 0.5% auto;
      display: block;
    }

    .btn2 {
      margin-top: 11px;

      &.join-company-pc {
        margin-top: 26px;
      }
    }
  }

  .top2 {
    margin-top: -1px;
    position: relative;


  }
}

.tips-pop {
  padding: 48px 40px 40px 40px;
  border-radius: 12px;
  text-align: center;
  width: 590px;
  box-sizing: border-box;

  >p:first-child {
    font-size: 36px;
    font-weight: 600;
  }

  >p:nth-child(2) {
    font-size: 24px;
    color: #242831;
    margin-top: 32px;
    line-height: 2;
  }

  div {
    width: 510px;
    height: 96px;
    line-height: 96px;
    border-radius: 8px;
    margin-top: 48px;
    background-color: #FFDE00;
    font-weight: 600;
    font-size: 32px;
  }

}

.top {
  position: fixed;
  right: 32px;
  bottom: 160px;
  width: 86px;
  height: 86px;

  &.join-company-pc {
    width: 56px;
    height: 56px;
    right: 60px;
    bottom: 60px;
  }
}

@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0;
  width: @bei*600;
  height: @bei*1066;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

}
</style>
